<template>
    <div>

        <!--Create Addresses Modal-->
        <b-modal no-close-on-backdrop scrollable  title="Create Address" class="modal-dark" v-model="createAddress">
            <b-row>
                <b-col sm="6">
                    <b-form-group>
                        <label>Address</label>
                        <b-form-input v-model="newAddress.address1"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Apartment/Unit</label>
                        <b-form-input v-model="newAddress.address2"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>City</label>
                        <b-form-input v-model="newAddress.city"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>State</label>
                        <b-form-select :options="states" v-model="newAddress.state"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Zip Code</label>
                        <b-form-input type="number" v-model.number="newAddress.zip"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newAddress.notes, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in newAddress.notes" :key="i"  v-if="refresh">
                            <b-form-input v-model="newAddress.notes[i].text" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, newAddress.notes)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createAddress=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="postAddress(newAddress)">Submit</b-button>
            </template>
        </b-modal>

        <!-- Address edit Modal -->
        <b-modal no-close-on-backdrop scrollable  title="Edit Address" class="modal-dark" id="address-modal" size="lg" v-model="modalOpen">
            <b-row>
                <b-col sm="6">
                    <b-form-group>
                        <label>Address 1</label>
                        <b-form-input v-model="openAddress.address1"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Address 2</label>
                        <b-form-input v-model="openAddress.address2"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>City</label>
                        <b-form-input v-model="openAddress.city"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>State</label>
                        <b-form-select :options="states" v-model="openAddress.state"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Zip Code</label>
                        <b-form-input type="number" v-model.number="openAddress.zip"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(openAddress.notes, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in openAddress.notes" :key="i"  v-if="refresh">
                            <b-form-input v-model="openAddress.notes[i].text" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, openAddress.notes)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="modalOpen=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="putAddress(openAddress)">Submit</b-button>
            </template>
        </b-modal>

        <!-- Address show Modal -->
        <b-modal no-close-on-backdrop scrollable  title="Address" class="modal-dark" id="address-modal" size="lg" v-model="modalSOpen">
            <b-col sm="12">
                <label>Address1: {{ showAddress.address1 }}</label>
            </b-col>
            <b-col sm="12">
                <label>Address2: {{ showAddress.address2 }}</label>
            </b-col>
            <b-col sm="12">
                <label>City: {{ showAddress.city }}</label>
            </b-col>
            <b-col sm="12">
                <label>State: {{ showAddress.state }}</label>
            </b-col>
            <b-col sm="12">
                <label>Zip Code: {{ showAddress.zip }}</label>
            </b-col>
            <b-col sm="12">
                <label>Notes:</label>
                <ul>
                    <li v-for="(note, id) in showAddress.notes" :key="id">{{ note.text }}</li>
                </ul>
            </b-col>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="modalSOpen=false">Cancel</b-button>
            </template>
        </b-modal>

        <!-- Address Table -->
        <b-card style="width: 100%; height: 100%">
            <div slot="header">
                <span class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0"><b>Address Manager</b></h5>
                    <fa-icon :icon="['fas', 'square-plus']" size="lg" style="float: right; cursor: pointer;" @click="openCreateAddress"/>
                </span>
            </div>
            <v-client-table @row-click="rowClicked" ref="dTable" style="width: 100%" :columns="columns" :data="addresses" :options="tOptions" :theme="theme" class="dataTable">
                <div slot="address1" slot-scope="props">
                    <span v-if="props.row.address1 !== null">{{props.row.address1}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="address2" slot-scope="props">
                    <span v-if="props.row.address2 !== null">{{props.row.address2}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="city" slot-scope="props">
                    <span v-if="props.row.city !== null">{{props.row.city}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="state" slot-scope="props">
                    <span v-if="props.row.state !== null">{{props.row.state}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="zip" slot-scope="props">
                    <span v-if="props.row.zip !== null">{{props.row.zip}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="info" slot-scope="props">
                    <div  style="float: right">
                        <fa-icon :icon="['fas', 'info']" style="cursor: pointer;" size="lg" @click="openSModal(props.row)"/>&nbsp;
                        <fa-icon :icon="['fas', 'pen-to-square']" style="cursor: pointer;" size="lg" @click="openEModal(props.row)"/>
                    </div>
                </div>
                <div slot="h__info">
                    <span style="float: right; margin-bottom: 0">Info/Edit</span>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""></b-tooltip>
        </b-card>
    </div>
</template>

<script>
    import Vue from 'vue'
    import risc from '@/services/risc.js'
    import { ClientTable } from 'vue-tables-2'

    Vue.use(ClientTable)

    export default {
        data() {
            return {
                columns: ['address1', 'address2', 'city', 'state', 'zip', 'info'],
                createAddress: false,
                fields: {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: 0,
                    notes: [
                        { text: ''}
                    ]
                },
                modalOpen: false,
                modalSOpen: false,
                newAddress: {},
                openAddress: {},
                showAddress: {},
                refresh: true,
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                tooltipTitle:"",
                tooltipRender:true,
                target: '',
                theme: 'bootstrap4',
                tOptions: {
                    sortable: ['address1', 'address2', 'city', 'state', 'zip'],
                    filterable: ['address1', 'address2', 'city', 'state', 'zip'],
                    orderBy: {column: 'name', ascending: true},
                    headings: {
                        address1: 'Address 1',
                        address2: 'Address 2',
                        city: 'City',
                        state: 'State',
                        zip: 'Zip Code',
                        info: 'Info/Edit'
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                addresses: [],
                states: [
                    'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
                    'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
                    'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
                    'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
                    'VT','VI','VA','WA','WV','WI','WY'
                ]
            }
        },
        methods: {
            getAddresses() {
                risc.getAddresses().then(response => {
                    this.addresses = response.data
                })
            },
            rowClicked(item) {
                //ctrl click to make tooltip appear with id
                if (item.event.ctrlKey) {
                    this.tooltipRender = false;
                    this.tooltipTitle = item.row._id;
                    item.event.target.id = item.row._id;
                    this.target = item.row._id;
                    //restart tooltip so it can map to its new position
                    Vue.nextTick().then(() => {
                        this.tooltipRender = true;
                        Vue.nextTick().then(() => {
                            this.$refs.tooltip.$emit('open')
                            setTimeout(function () {
                                //make the tooltip disappear automatically
                                this.$refs.tooltip.$emit('close');
                            }.bind(this), 4000)
                        });
                    });
                }
            },
            openCreateAddress(){
                this.newAddress = {};
                this.newAddress = JSON.parse(JSON.stringify(this.fields))
                this.createAddress = true;
            },
            openEModal(info) {
                risc.getAddress(info.addressId).then(response => {
                    this.openAddress = response.data
                })
                this.openAddress = JSON.parse(JSON.stringify(info))
                Object.keys(this.fields).forEach(field => {
                    if(this.openAddress[field] === undefined) {
                        this.openAddress[field] = JSON.parse(JSON.stringify(this.fields[field]))
                    }
                    else if(Array.isArray(this.openAddress[field]) && this.openAddress[field].length === 0) {
                        this.openAddress[field] = JSON.parse(JSON.stringify(this.fields[field]))
                    }
                })
                this.modalOpen = true
            },
            openSModal(info) {
                this.showAddress = JSON.parse(JSON.stringify(info))
                risc.getAddress(info.addressId).then(response => {
                    this.showAddress = response.data
                })
                this.modalSOpen = true
            },
            postAddress(){
                this.createAddress = false;
                Object.keys(this.fields).forEach(field => {
                    if(JSON.stringify(this.newAddress[field]) === JSON.stringify(this.fields[field]) || this.newAddress[field] === undefined){
                        delete this.newAddress[field];
                    }
                })
                risc.postAddress(this.newAddress).then(() => {
                    this.getAddresses();
                })
            },
            putAddress() {
                this.modalOpen = false
                risc.putAddress(this.openAddress).then(() => {
                    this.getAddresses()
                })
            },
            push(field, item){
                field.push(JSON.parse(JSON.stringify(item)));
                this.refresh=false;
                this.refresh=true;
            },
            splice(index, field) {
                field.splice(index, 1);
                this.refresh=false;
                this.refresh=true;
            }
        },
        created() {
            this.getAddresses()
        }
    }
</script>